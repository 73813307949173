import React from "react";
import PropTypes from "prop-types";
//import { useStaticQuery, graphql } from "gatsby";
import Icon from "react-icons-kit";
import Fade from "react-reveal/Fade";
import { openModal, closeModal } from "@redq/reuse-modal";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import TiltShape from "../TiltShape";
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  VideoModal,
  PlayButton,
  VideoWrapper,
} from "./banner.style";
import SmartText from "../../common/components/SmartText";
//import { ic_play_circle_filled } from "react-icons-kit/md/ic_play_circle_filled";
import { play } from "react-icons-kit/entypo/play";
import AppImage from "../../images/footerapp.svg";
import PlaystoreImage from "../../images/footerplay.svg";
import Image from "common/components/Image";
//import GatsbyImage from "common/components/GatsbyImage";
import { getImage } from "gatsby-plugin-image";
import Logo from "common/components/UIElements/Logo";
import MaslolLogoAlt from "images/test.png";
import MaslolLogo from "images/foterLogo.png";
import { marginBottom } from "styled-system";
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = ({ tourTrail }) => {
  return (
    <VideoWrapper>
      <iframe
        title="Video"
        src={tourTrail ? tourTrail.url : null}
        frameBorder="0"
      />
    </VideoWrapper>
  );
};

const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  guideName,
  tourDesc,
  tourTitle,
  tourTrail,
  handlePaymentModal,
  lang = "He",
  indexPage = false,
  secTitle,
  gatsbyImage = false,
}) => {
  // modal handler

  const image = getImage(tourTrail.poster_url);
  console.log("image", image);
  const textLang = {
    guideHead: { He: "הסיור בהדרכת: ", En: "Your Guide: " },
    actionBut: { He: "קנו עכשיו וטיילו בזמן שלכם", En: "Start Now" },
    videoBut: { He: "צפה בוידיאו", En: "Watch Trailer" },
    downloadAction: {
      He: "הורידו עכשיו",
      En: "Download our app now, tour it in your way",
    },
    bigHeader: {
      He: "האפליקציה שהולכת לשנות את עולם הטיולים שלך",
      En: "An app that will change the way you travel",
    },
  };

  return (
    <BannerWrapper id="banner_section">
      <TiltShape className="banner-shape" />
      <Container>
        <Box {...row}>
          <Logo
            /* href="/saasclassic" */
            href="/"
            logoSrc={MaslolLogo}
            title="Portfolio"
            style={{
              marginRight: "15px",
              margin: "auto",
            }}
            className="main-logo"
          />
          <iframe
            src="https://dguide-tour.web.app/tour/fZpUFiTLR1Zmmcr5kzKT"
            title="Maslol system"
            style={{ width: "400px", height: "750px", marginBottom: "100px" }}
            allow="geolocation *"
          ></iframe>
          <Box {...imageWrapper}>
            {/* <Fade bottom> */}
            <div style={{ margin: "auto" }}>
              {/* <VideoModal>
               {!gatsbyImage ? (
                  <img
                    src={tourTrail.poster_url}
                 
                    alt="banner"
                    className="bannerImg"
                  />
                ) : (
                  <GatsbyImage
                    image={image}
                    alt={`Interior Landing by RedQ`}
                    className="bannerImg"
                    width={200}
                    height={200}
                  />
                )}
               <PlayButton tabIndex="0" onClick={handleVideoModal}>
                  <Icon icon={play} size={40} />
                </PlayButton>
              </VideoModal> */}
            </div>
            {/*  </Fade> */}
            {indexPage && (
              <div style={{ marginBottom: "60px" }}>
                <Heading
                  {...secTitle}
                  content={textLang["downloadAction"][lang]}
                />
                <div className="bannerImageBtn">
                  <Fade bottom>
                    <a href="https://apple.co/3u4oUSp">
                      <Image
                        src={AppImage}
                        alt="App Image"
                        className="app_image_area"
                      />
                    </a>

                    <a href="https://bit.ly/3qqKOx5">
                      <Image
                        src={PlaystoreImage}
                        alt="PlaystoreImage Image"
                        className="app_image_area"
                      />
                    </a>
                  </Fade>
                </div>
              </div>
            )}
          </Box>
          {/* {!indexPage && (
            <Box {...contentWrapper} style={{ margin: "15px 0 75px 0" }}>
              <SmartText
                text={tourDesc}
                style={description}
                length={!handlePaymentModal ? 500 : 250}
                readMoreColor={"white"}
                lang={lang}
              />
            </Box>
          )} */}
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  subtitle: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
  secTitle: PropTypes.object,
};

BannerSection.defaultProps = {
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "36px"],
    mr: "40px",
    ml: "40px",
    fontWeight: "600",
    color: "#15172C",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "30px"],
    textAlign: "center",
    fontFamily: "Poppins",
    color: "white",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  contentWrapper: {
    width: ["100%", "100%", "80%", "65%", "60%"],
    mb: "40px",
    m: "auto",
    // flexBox: true,
    justifyContent: "center",
    // display: "flex",
    // flexDirection: "column",
  },
  title: {
    fontSize: ["24px", "32px", "40px", "42px", "46px"],
    fontWeight: "700",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px", "25px", "25px", "25px"],
    lineHeight: "1.2",
    textAlign: "center",
  },
  subtitle: {
    fontSize: ["16px", "20px", "26px", "28px", "28px"],
    fontWeight: "350",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px", "25px", "25px", "25px"],
    lineHeight: "1.2",
    textAlign: "center",
  },
  description: {
    fontSize: ["17px", "18px", "18px", "18px", "18px"],
    color: "#fff",
    lineHeight: "1.75",
    mb: "0",
    textAlign: "center",
  },
  discountAmount: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#fff",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "400",
    color: "#fff",
    mb: 0,
    as: "span",
    diraction: "rtl",
  },
  fillButton: {
    type: "button",
    fontSize: ["17px", "18px"],
    fontWeight: "900",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    colors: "secondaryWithBg",
    color: "white",
    minWidth: ["auto", "150px"],
    height: ["40px", "46px"],
    minHeight: "auto",
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: "center",
    mt: "35px",
  },
  button: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    color: "#fff",
    colors: "secondary",
    height: ["40px", "46px"],
    minHeight: "auto",
  },
  imageWrapper: {
    flexBox: true,
    flexDirection: "column",
    m: "auto",
  },
};

export default BannerSection;
