import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import BannerSection from "Containers/BannerMaslol";
import Navbar from "Containers/Navbar";
import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import Footer from "Containers/Footer";
import Features from "../Containers/Features";
import FormSection from "../Containers/FormSection";
import mainImg from "../images/main.webp";

const SaasClassic = ({}) => {
  /* const [Tour, setTour] = React.useState();
  const [reviews, setReviews] = React.useState();
  const [profileVideo, setProfileVideo] = React.useState(null);
  const [profilePic, setProfilePic] = React.useState(null);
  const [paymeURL, setPaymeURL] = React.useState(null);
  const [source, setSource] = React.useState("Dguide"); */
  const [lang, setLang] = React.useState("En");

  /*  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    let source_id = params.get("source");
    if (!source_id) {
      source_id = "Dguide";
    }

    setSource(source_id);

    async function getParams() {}

    getParams();
  }, [Tour]); */

  const textByLang = {
    mainTitle: { En: "Welcome to Maslol!", He: "ברוכים הבאים ל Maslol" },
    subMainTitle: {
      En: "Enjoy an exposure to a whole new audience of travelers, and provide your customers with a guided tour at their own time, and at their own pace",
      He: "היחשפו לקהל מטיילים חדש שעוד לא הכרתם ותספקו ללקוחתייכם סיור מודרך בזמן שלהם ובקצב שלהם",
    },
    textMainTitle: {
      En: "About 70% of travelers worldwide want to enjoy a guided tour, but at their own time and pace, without being a part of a larger group. Using the first tour-creating platform, and the high-tech self-guided tours app, you could provide your customers a convenient and fascinating experience that will provide your business with an extra boost!",
      He: "כ70% מהמטיילים בעולם רוצים להנות מסיור מודרך בזמן שלהם ובקצב שלהם וללא קבוצת מטיילים גדולה. בעזרת פלטפורמת יצירת הסיורים הראשונה מסוגה ואפליקציית הסיורים המדורכים עצמאית הטכנולוגית ביותר תספקו ללקוחתיכם חוויה נוחה ומרתקת שתספק לעסק שלכם מנוע הכנסה נוסף!",
    },
  };

  const videoObj = {
    url: "https://www.youtube.com/embed/YeY_KgZeqSI",
    poster_url: mainImg,
  };

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <Seo
          title={`Dguide | Agency`}
          description={
            "A page for travle agency that want to sell self guided tours"
          }
          lang={lang}
          meta={[
            "סיורים",
            "סיורים בירושלים",
            "טיול בירושלים",
            "טיול",
            "טיול למשפחה",
            "מקומות לטייל בשבת",
            "איפה אפשר לטייל",
            "המלצות לטיולים",
            "טיול יום",
            "סיור יום",
            "אטרקציות בירושלים",
            "סיור עצמאי",
            "מדריך טיולים",
            "יום כיף",
            "מה לעשות בירושלים",
            "סיורים מודרכים בירושלים",
          ]}
          keywords={["סיור מודרך", "סיור עצמאי"]}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          {/* <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <DrawerProvider>
                <Navbar
                  lang={lang}
                  setLang={setLang}
                  logo="MS"
                  returnPayment={true}
                />
              </DrawerProvider>
            </DrawerProvider>
          </Sticky> */}
          <BannerSection
            guideName={false}
            tourTitle={textByLang["mainTitle"][lang]}
            tourDesc={textByLang["textMainTitle"][lang]}
            subTitle={textByLang["subMainTitle"][lang]}
            tourTrail={videoObj}
            handlePaymentModal={false}
            lang="En"
          />
          <Features lang={lang} />
          <FormSection lang={lang} />
          <Footer lang="En" logo="MS" />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default SaasClassic;
