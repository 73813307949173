import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "common/components/Box";
import Heading from "common/components/Heading";
import Input from "common/components/Input";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import FormImg from "../../images/FormImg.svg";
import axios from "axios";
import NewsletterSectionWrapper, {
  NewsletterForm,
} from "./newsletterSection.style";

const FormSection = ({
  sectionHeader,
  sectionTitle,
  inputTitle,
  btnStyle,
  inputStyle,
  lang,
}) => {
  const textLang = {
    Fname: { En: "First Name", He: "שם פרטי" },
    Lname: { En: "Last Name", He: "שם משפחה" },
    Agnname: { En: "Agency Name", He: "שם סוכנות" },
    Email: { En: "Email", He: "אימייל" },
    Phone: { En: "Phone", He: "טלפון" },
    success: {
      En: "We got your details, someone will contact you soon",
      He: "קיבלנו את הפרטים שלך מישהו יחזור אליך בקרוב!",
    },
    mainTitle: { En: "Sign Up Today", He: "הרשמו עוד היום" },
  };
  const [serverState, setServerState] = useState(false);
  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(
      "SUBMIT",
      `https://europe-central2-tours-app-1579553856346.cloudfunctions.net/newAgentPage/?fName=${e.target[0].value}&lName=${e.target[1].value}&aName=${e.target[2].value}&email=${e.target[3].value}&phone=${e.target[4].value}`
    );
    const getURL = `https://europe-central2-tours-app-1579553856346.cloudfunctions.net/newAgentPage/?fName=${e.target[0].value}&lName=${e.target[1].value}&aName=${e.target[2].value}&email=${e.target[3].value}&phone=${e.target[4].value}`;
    console.log(getURL);
    axios({
      method: "get",
      url: getURL,
      /* data: new FormData(form), */
    })
      .then((r) => {
        setServerState(true);
      })
      .catch((r) => {
        setServerState(false, r.response.data.error);
      });
  };

  return (
    <NewsletterSectionWrapper id="newsletterSection">
      <Container>
        <div
          className="flexTwist"
          /* style={{ display: "flex", flexDirection: "row" }} */
        >
          <div className="leftForm">
            <img src={FormImg} style={{ width: "100%" }} />
          </div>
          <div className="rightForm">
            <Box {...sectionHeader}>
              <Heading
                content={textLang["mainTitle"][lang]}
                {...sectionTitle}
              />
            </Box>
            <Box>
              {serverState ? (
                <Heading content={textLang["success"][lang]} {...inputTitle} />
              ) : (
                <NewsletterForm>
                  <form onSubmit={handleOnSubmit}>
                    <div className="inputForm">
                      <Heading
                        content={textLang["Fname"][lang]}
                        {...inputTitle}
                      />
                      <Input
                        inputType="text"
                        isMaterial={false}
                        placeholder={textLang["Fname"][lang]}
                        name="Fname"
                        id="Fname"
                        aria-label="Fname"
                        {...inputStyle}
                      />
                    </div>
                    <div className="inputForm">
                      <Heading
                        content={textLang["Lname"][lang]}
                        {...inputTitle}
                      />
                      <Input
                        inputType="text"
                        isMaterial={false}
                        placeholder={textLang["Lname"][lang]}
                        name="Lname"
                        aria-label="Lname"
                      />
                    </div>
                    <div className="inputForm">
                      <Heading
                        content={textLang["Agnname"][lang]}
                        {...inputTitle}
                      />
                      <Input
                        inputType="text"
                        isMaterial={false}
                        placeholder={textLang["Agnname"][lang]}
                        name="Lname"
                        aria-label="Lname"
                      />
                    </div>
                    <div className="inputForm">
                      <Heading
                        content={textLang["Email"][lang]}
                        {...inputTitle}
                      />
                      <Input
                        inputType="email"
                        isMaterial={false}
                        placeholder={textLang["Email"][lang]}
                        name="email"
                        aria-label="email"
                      />
                    </div>
                    <div className="inputForm">
                      <Heading
                        content={textLang["Phone"][lang]}
                        {...inputTitle}
                      />
                      <Input
                        inputType="tel"
                        isMaterial={false}
                        placeholder={textLang["Phone"][lang]}
                        name="phone"
                        aria-label="phone"
                      />
                    </div>
                    {!serverState && (
                      <Button type="submit" title="SEND NOW" {...btnStyle} />
                    )}
                  </form>
                </NewsletterForm>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </NewsletterSectionWrapper>
  );
};

// NewsletterSection style props
FormSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  inputStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  inputTitle: PropTypes.object,
};

// NewsletterSection default style
FormSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: "56px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  // button default style
  btnStyle: {
    minWidth: "152px",
    minHeight: "45px",
    fontSize: "14px",
    fontWeight: "500",
    mt: "8px",
  },
  inputStyle: {
    minWidth: "152px",
    minHeight: "45px",
    fontSize: "14px",
    fontWeight: "500",
  },
  inputTitle: {
    /*  textAlign: "left", */
    fontSize: "18px",
    fontWeight: "350",
    mb: "0",
    pl: "6px",
    pr: "6px",
  },
};

export default FormSection;
