import React from "react";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import FeatureBlock from "common/components/FeatureBlock";
import { SectionHeader } from "./app-minimal.style";
import SectionWrapper, { FeatureWrapper } from "./features.style";
import agenctWhy1 from "../../images/agencyWhy1.webp";
import agenctWhy2 from "../../images/agencyWhy2.webp";
import agenctWhy3 from "../../images/agencyWhy3.webp";
import agenctWhy4 from "../../images/agencyWhy4.webp";
import Icon from "react-icons-kit";
import { checkmark } from "react-icons-kit/icomoon/checkmark";

const Features = ({ lang }) => {
  const textLang = {
    title: {
      He: "אז למה כדאי לכם ליצור את הסיור שלכם בDguide?",
      En: "So, why should you use Dguide for your tour?",
    },
    img: "agenctWhy1",
  };
  const items = [
    {
      id: "123",
      title: { En: "Maxmize your leads", He: "אל תפספס אף לקוח" },
      description: {
        He: "זוכרים את הלקוח שהתעניין בסיור אבל לא נותרו עוד מקומות בקבוצה? זוכרים את הלקוח שהתעניין בסיור פרטי אך העלות שלו הייתה גבוהה? עם Dguide כל ליד שווה כסף וכל ליד יוכל להנות מסיור בכל זמן ובכל שעה במחיר לכל כיס.",
        En: "Remember that customer who was interested in your tour, but there were no more spots left? Remember the customer who was interested in a private tour, but it was too expensive for them? Using Dguide, every lead is cashable, and every lead can enjoy a guided tour at any time, and at a friendly price.",
      },
      img: agenctWhy2,
    },
    {
      id: "456",
      title: { En: "Your digital asset", He: "נכס דיגיטלי משלך" },
      description: {
        He: "משקיעים פעם אחת, נהנים כל החיים- המוצרים שלנו נמכרים במגוון פלטפורמות ומייצרים מדי חודש הכנסה נוספת למאות סוכנויות ומדריכי טיולים בעולם",
        En: "Invest once, enjoy for a lifetime - Our products are being sold over several platforms and can generate extra income for hundreds of travel guides and agencies worldwide.",
      },
      img: agenctWhy1,
    },
    {
      id: "789",
      title: { En: "Our support", He: "השירות שלנו" },
      description: {
        He: "תוכן שלכם, השירות שלנו- שנים רבות של ניסיון בתחום התיירות בליווי הצוות שלנו יצרו את הסיור המושלם והמרתק, אנחנו נדאג לשקט שלכם ולא תצטרכו להתעסק בלוגיסטיקה או תמיכה טכנית",
        En: "Your content, our service - With years of experience in the tourism field, with our team you could create the perfect and most fascinating tour, and we will take care of all the logistics and technical support, so you won’t have to worry about it.",
      },
      img: agenctWhy3,
    },
    {
      id: "101112",
      title: { En: "Get to know FIT travelers", He: "היחשפו לקהל חדש" },
      description: {
        He: "תהנה ממטילים עצמאיים מכל העולם- קהילת המטיילים שלנו הולכת וגדלה ואתם תוכלו להנות ממטילים אלו אשר נהנו בעבר מהסיורים שלנו  וירכשו גם את הסיור שלכם",
        En: "Enjoy independent travelers around the world - Our travelers community is growing and growing, and you could enjoy every solo traveler who has used our services before, and might purchase your tour, next!",
      },
      img: agenctWhy4,
    },
  ];

  const benefits = [
    {
      id: "ben1",
      text: {
        En: "Full step by step map environment for the costumers to enjoy",
      },
    },
    {
      id: "ben2",
      text: {
        En: "No more logistics cost and extra hours of planning the tour according to the hours you have.",
      },
    },
    {
      id: "ben3",
      text: {
        En: "Offline video abilities for the content.",
      },
    },
    {
      id: "ben4",
      text: {
        En: "Embedded code for distribution to anywhere you want.",
      },
    },
    {
      id: "ben5",
      text: {
        En: "Easy to share to all social platforms and fully branded by you.",
      },
    },
  ];

  return (
    <SectionWrapper id="service_section">
      <Container>
        <SectionHeader className="text-white">
          {/*   <Heading content={textLang["title"][lang]} /> */}
          {/*  <Text content={"description"} /> */}
        </SectionHeader>
        {/*  <FeatureWrapper>
          {items.map((item) => (
            <FeatureBlock
              key={`feature-key${"item.id"}`}
              icon={
                <Image
                  style={{ height: "130px", width: "130px" }}
                  src={item.img}
                  alt={item.title[lang]}
                />
              }
              title={<Heading as="h3" content={item.title[lang]} />}
              description={<Text content={item.description[lang]} />}
            />
          ))}
        </FeatureWrapper> */}
        <div
          class="benefitsArea"
          style={{ margin: "auto", textAlign: "center" }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize: "22px",
              color: "black",
            }}
            content={
              "The Solo Travelers Market Grow by 761.15% Post Pandemic. Now it's Almost Tripled."
            }
          />

          {benefits.map((ben) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                icon={checkmark}
                style={{
                  color: "#18d379",
                  margin: "0px 10px 15px 10px",
                }}
                size={15}
              />
              <Text
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "black",
                }}
                content={ben["text"][lang]}
              />
            </div>
          ))}
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default Features;
